import React, { useRef, useEffect } from 'react';

import './Social.scss';

const Social = () => {

	const parentNode = useRef(null);
	const canvasNode = useRef(null);
	const URL = 'https://plugin.screenplify.com/c/embed/instagram/eyJ1bmlxdWVfaWQiOiJjOTNhZDhhOTI3YjAxY2VlMjJhYWYzODVhMjFkNTY5ZCJ9';

	useEffect(() => {
		
		window.addEventListener("resize", handleScale);

		handleScale();

		return () => {
			window.removeEventListener("resize", handleScale);
		}

	}, []);


	const handleScale = () => {
		const parentWidth = parentNode.current.offsetWidth - 30; // 30 for padding
		const canvasWidth = canvasNode.current.offsetWidth;
		const canvasHeight = canvasNode.current.offsetHeight;
		const scale = ((parentWidth * 100) / canvasWidth);
		const height = (canvasHeight * scale) / 100;

		parentNode.current.style.height = `${height}px`;
		canvasNode.current.style.transform = `scale(${scale / 100})`;
	}

	return (
		<section className="section py-5">
			<div className="container">
				
				<div className="section-header text-center">
					<small>Highlight</small>
					<h2>Social Plugin</h2>
					<hr />
				</div>

				<div className="row py-5">

					<div className="col-12 col-lg-7" ref={parentNode}>
						<div className="laptop-animation" ref={canvasNode}>
							<div className="screen-base">
								<div className="screen-wrap">
									<iframe src={ URL } title="frame" frameBorder="0" seamless className="screen-frame"></iframe>
								</div>
							</div>
							<div className="laptop"></div>
						</div>
					</div>

				
					<div className="col-12 col-lg-5 d-flex align-items-center">
						<div className="py-4">
							<p className="lead ftw-400 text-muted ls-5">Your social content on autoplay. Capture, collect and display your Facebook, Twitter, Instagram and user-generated hashtag posts on your Screenplify Screens.</p>
							<p className="lead ftw-400 text-muted ls-5">It creates trust between you and the customers, increase conversions, builds your reputation and best of all it is visually striking.</p>
						</div>
					</div>

				</div>




			</div>
		</section>
	)
}

export default Social;