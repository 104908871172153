import React from 'react';

import Hero from './Hero';
import Social from './Social';
import Plugins from './Plugins';
import PluginList from '../PluginsList.js';
import ContactUs from './ContactUs';

export default function Home() {
	return (
        <React.Fragment>
            <Hero />
            <Social />
            <Plugins pluginList={ PluginList } cols={ 3 } />
            <ContactUs/>
        </React.Fragment>
	)
}