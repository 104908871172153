import React from 'react';

import './Plugins.scss';

import List from '../List';

const Plugins = ({ pluginList, cols}) => {

    const Cols = cols;
    let Lists = [];

    for (var i = 0; i < (pluginList.length / Cols); i++) {
        var temp = [];

        for (var k = 0; k < Cols; k++) {
            let idx = (i * Cols) + k;

            if(typeof pluginList[ idx ] !== 'undefined')
                temp[k] =  pluginList[ idx ];
        }

        Lists[i] = temp;
    }


	return (
		<section className="section py-5">
			<div className="container">
				
				<div className="section-header text-center">
					<small>Tool Belt</small>
					<h2>All Our Plugins</h2>
					<hr />
				</div>

				<div className="py-5">

	                { Lists.map((array, index) => (
	                    <List plugins={ array } cols={ Cols } key={ index }/>
	                ))}

				</div>


			</div>
		</section>
	)
}

export default Plugins;