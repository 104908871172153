import React, { Component } from 'react';
// import { Link } from "react-router-dom";

class List extends Component {

	// constructor(props) {
	// 	super(props);
	// }


	render() {
		const { plugins, cols } = this.props;
		const colsClass = 
				(cols === 2)? 'col-md-6':
				(cols === 3)? 'col-md-4':
				(cols === 4)? 'col-md-3': '';

		return (
			<div className="section plugins-list">
				<div className="container">
					<div className="row d-flex align-items-stretch mb-4">

						{ plugins.map((item, index) => (
							
							<div className={ `col-12 ${colsClass} d-flex align-items-stretch justify-items-stretch mb-4` } key={index}>
								<a href={ `/info/${item.slug}` } className="d-block card border-0 shadow" style={{pointerEvents: 'none'}}>
									<div className="card-body text-center">
										<img src={ item.image } className="img-fluid" alt={ item.name } />
										<h5 className="card-title text-uppercase text-dark">{ item.name }</h5>
										<p className="card-text text-muted">{ item.description }</p>
									</div>
								</a>
							</div>

						)) }

					</div>
				</div>

			</div>
		);
	}
}

export default List;