import React, { useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';

import './Hero.scss';
import Notes from '../MusicalNotes';
import Clock from '../AnalogClock';
import Snowy from '../WeatherIcon/Snowy';
import SVGText from './assets/text.svg';
import MapMarker from './assets/map-markers.svg';
import Sun from './assets/sun.svg';

const Hero = () => {

	const parentNode = useRef(null);
	const canvasNode = useRef(null);

	useEffect(() => {
		
		window.addEventListener("resize", handleScale);

		handleScale();

		return () => {
			window.removeEventListener("resize", handleScale);
		}

	}, []);


	const handleScale = () => {
		const parentWidth = parentNode.current.offsetWidth - 30; // 30 for padding
		const canvasWidth = canvasNode.current.offsetWidth;
		const canvasHeight = canvasNode.current.offsetHeight;
		const scale = ((parentWidth * 100) / canvasWidth);
		const height = (canvasHeight * scale) / 100;

		parentNode.current.style.height = `${height}px`;
		canvasNode.current.style.transform = `scale(${scale / 100})`;
	}



	return (
		<div className="section hero-section pb-5">
			<div className="container">
				<div className="row py-5">
					
					<div className="col-12 col-lg-5 d-flex align-items-center">
						<div className="pt-5 text-center text-lg-left w-100">
							<h1 className="ftw-600 m-0" >Screenplify Plugins</h1>
							<p className="lead ftw-400 ls-5 text-muted py-3">Tools Built to Further Enhance Your Screenplify Experience.</p>
						</div>
					</div>

					<div className="col-12 col-lg-7" ref={parentNode}>

						<div className="hero-animation" ref={canvasNode}>
							<div className="bubbles heart"></div>
							<div className="bubbles heart"></div>

							<div className="bubbles like"></div>
							<div className="bubbles like"></div>

							<div className="bubbles facebook"></div>
							<div className="bubbles instagram"></div>
							<div className="bubbles twitter"></div>

							<div className="bursting-heart"></div>
							<div className="twitter-share"></div>
							<div className="facebook-like"></div>

							<Notes />

							<div className="text-svg">
								<ReactSVG src={ SVGText } />
							</div>
							
							<div className="analog-clock">
								<Clock />
							</div>

							<div className="map-marker">
								<ReactSVG src={ MapMarker } />
							</div>

							<div className="weather-icon">
								<ReactSVG src={ Sun } />
								<Snowy />
							</div>

						</div>

					</div>

				</div>
			</div>
		</div>
	)
}

export default Hero;