import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from './components/Navbar';
import Home from './components/Home';
// import Info from './components/Info';
import Footer from './components/Footer';

class App extends Component {

    // constructor(props) {
    //     super(props);
    // }
    
    render() {
        
        /* return (
            <Router onUpdate={() => window.scrollTo(0, 0)}>
                <Navbar />
            
                <Switch>
                    <Route exact path="/" component={ Home } />
                    <Route exact path="/info/:id" component={ Info } />
                </Switch>
                
                <Footer />
            </Router>
        ); */

        return (
            <>
                <Navbar />
                <Home />
                <Footer />
            </>
        )
    }
}

export default  App;