import React from 'react';
import HTMLComment from 'react-html-comment';

import './index.scss';

import { ReactComponent as Facebook } from './svgs/facebook.svg';
import { ReactComponent as Instagram } from './svgs/instagram.svg';
import { ReactComponent as Twitter } from './svgs/twitter.svg';
import { ReactComponent as Whatsapp } from './svgs/whatsapp.svg';


const ContactUs = () => {
    return (
        <section className="section py-5 mb-5 contactus">
            <div className="container mb-5">


                <HTMLComment text="Icons From https://www.flaticon.com/packs/social-15 " />

                <div className="section-header text-center">
                    <small>Contact Us</small>
                    <h2>Get In Touch</h2>
                    <hr />
                </div>

                <div className="row d-flex aligh-items-center justify-content-center">
                    <div className="col-3 col-md-3 text-center">
                        <a href="https://www.facebook.com/screenplify" className="contactus-link my-3" target="_blank" rel="noopener noreferrer">
                            <Facebook />
                        </a>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                        <a href="https://www.instagram.com/screenplify" className="contactus-link my-3" target="_blank" rel="noopener noreferrer">
                            <Instagram />
                        </a>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                        <a href="https://twitter.com/screenplify" className="contactus-link my-3" target="_blank" rel="noopener noreferrer">
                            <Twitter />
                        </a>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                        <a href={`https://wa.me/60126733995?text=${encodeURI('I am interested in your plugins')}`} className="contactus-link my-3" target="_blank" rel="noopener noreferrer">
                            <Whatsapp />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default ContactUs;