
const PluginsList = [
	{
		slug: 'spark',
		name: 'Spark',
		description: 'Display vibrant, smart, and detail-oriented agenda boards and conference programmes for events.',
		image: 'images/spark-logo.jpg',
		more: 'Spark screens was designed to display vibrant, smart, and detail-oriented agenda boards and conference programmes for events. Visitors love it because at a moment\'s glance, it makes it easy to decide what to do and where to go next. Exhibitor discovery adverts provide fantastic exposure for exhibitors. They can also generate significant sponsorship revenue for the organizer.',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	},
	{
		slug: 'amplify',
		name: 'Amplify',
		description: 'Play and queue high-quality audio across your screens in an order that fits all audible needs.',
		image: 'images/amplify-logo.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	},
	{
		slug: 'azan',
		name: 'Azan',
		description: 'Display the Islamic Prayer Times in a sensational design.',
		image: 'images/azan-logo.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/azan1.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan2.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan3.png'
		]
	},
	{
		slug: 'mapbox',
		name: 'Mapbox',
		description: 'A smart navigation system that lets you design and cruise maps using impeccable integration.',
		image: 'images/mapbox-logo.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/azan1.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan2.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan3.png'
		]
	},
	{
		slug: 'mapbox3d',
		name: 'Mapbox3D',
		description: 'The smart navigation system gets a kick. Cruise your maps in 3D and give your customers the best in Wayfinding.',
		image: 'images/mapbox-3d.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/azan1.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan2.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan3.png'
		]
	},
	{
		slug: 'lottie',
		name: 'Lottie',
		description: 'Easily add and play Lottie animations on your Screenplify devices.',
		image: 'images/Lottie.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/azan1.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan2.png',
			'https://www.screenplify.com/img/new/plugins/shots/azan3.png'
		]
	},
	{
		slug: 'weather',
		name: 'Weather',
		description: 'Check the clouds and show weather updates using this powerful plugin.',
		image: 'images/weather-logo.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	},
	{
		slug: 'socialvideo',
		name: 'Social Video',
		description: 'Stream adaptive YouTube, Vimeo and Facebook videos into your screens.',
		image: 'images/social-video.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	},
	{
		slug: 'socialalbums',
		name: 'Social Albums',
		description: 'Capture, collect and display your Facebook, Twitter, Instagram and user-generated hashtag posts on your screens.',
		image: 'images/Social-Albums.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	},
	{
		slug: 'multilayouts',
		name: 'Multi Layouts',
		description: 'Choose up to 4 layouts to be shown on a single screen. A bit crowded but can be useful in certain scenarios.',
		image: 'images/multi-layouts.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	},
	{
		slug: 'analogclock',
		name: 'Analog Clock',
		description: 'The classic clock for the old-school. It provides a light and dark mode. Customizable on request.',
		image: 'images/Analog-Clock.jpg',
		more: '',
		carousel: [
			'https://www.screenplify.com/img/new/plugins/shots/spark1.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark2.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark3.png',
			'https://www.screenplify.com/img/new/plugins/shots/spark4.png'
		]
	}
]

export default PluginsList;