
import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import './Navbar.scss';
import Logo from '../../svg/screenplify_logo.svg';

class Navbar extends Component {

	state = {
		open: false
	}

	render() {
		
		const { open } = this.state;
		const { links } = window.config.header;

		return (

			<nav id="main-nav" className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
				<div className="container">
					
					<a href="/" className="navbar-brand ls-8 ftw-600">
						<ReactSVG src={ Logo } />
					</a>

					<button onClick={ () => this.setState({ open: !open }) } className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					
					<div className={ 'collapse navbar-collapse ' + (open? 'show' : '') } id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							
							{
								links.map((item, index) => (
							
									<li className="nav-item" key={ index }>
										<a href={ item.link } target={ item.target } className="nav-link ls-8 ftw-600 px-3" >{ item.text }</a>
									</li>

								))
							}

						</ul>
					</div>

				</div>
			</nav>
		);
	}
}

export default Navbar;